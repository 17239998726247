<template>
  <div class="flex-center">
    Bitte über das &nbsp;<a href="https://portal.comline.app/">ComLine-Portal</a>&nbsp; anmelden!
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { auth } from "../plugins/firebase"
import { signInWithCustomToken } from  "firebase/auth";

export default {
  name: 'LoginComponent',
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
  data() {
    return {
      form: {
        email: "",
        password: ""
      },
      error: null
    };
  },
  methods: {
  },
  mounted() {
    let token = this.$route.params.token || 0
    signInWithCustomToken(auth, token)
    .then(()=>{
      this.$router.push({name: 'home'})
    })
    .catch(err=>{console.error(err)})
  }

};
</script>

<style scoped>
.flex-center{
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<style>
#wideBorder {
  border-top-width: 5px !important;
}

#navImpersonator {
  margin-top: 37px
}
</style>