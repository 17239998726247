<template v-if="this.user.loggedIn">
  <div>
    <b-list-group flush>
      <b-list-group-item class="d-flex align-items-center"><strong>AE Anzahl</strong>  <span class="ml-auto">{{data.incomingOrdersQty}}</span></b-list-group-item>
      <b-list-group-item class="d-flex align-items-center"><strong>AW Netto</strong>  <span class="ml-auto">{{data.incomingOrdersNet}} €</span></b-list-group-item>
      <b-list-group-item class="d-flex align-items-center"><strong>Lagerwert</strong>  <span class="ml-auto">{{data.stockValue}} €</span></b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>

export default {
  name: 'dayVarious',
  data(){
    return{

    }
  },
  props: {
    data: Object
  },
  methods: {

  },
}
</script>

<style scoped>
strong{
  margin-right: 16px;
}
</style>
