<template v-if="this.user.loggedIn">
  <div id="incoming-goods">
    <div id="fixed-table-header" :style="{ display: displayStyle }">
      <table class="table">
        <thead>
          <tr>
            <th>Art. Nr.</th>
            <th>SKU</th>
            <th>Wareneingang</th>
            <th>WE</th>
            <th>Lager</th>
            <th>Reserviert</th>
            <th>Frei</th>
            <th>Bestellt</th>
          </tr>
        </thead>
      </table>
    </div>
    <div class="m-3" v-if="data">
      <LastUpdate page="incommingGoods" class="mt-3" />
      <b-card no-body>
        <template #header>
          <div class="d-flex justify-content-center align-items-center">
            <div class="w-25">
              <b-form-input
                v-model="filter"
                placeholder="Suchen"
                type="search"
              ></b-form-input>
            </div>
          </div>
        </template>
        <b-table
          striped
          :items="data"
          :fields="fields"
          :filter="filter"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :responsive="true"
        >
        ></b-table>
      </b-card>
    </div>
    <div v-else-if="data === null" class="d-flex justify-content-center mb-3">
      <b-container>
        <b-alert class="w-100" show variant="warning"
          >Es sind keine Wareneingänge vorhanden.</b-alert
        >
      </b-container>
    </div>
    <div v-else class="d-flex justify-content-center mb-3">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LastUpdate from "../smallComponents/lastUpdate";

import { db } from "../plugins/firebase";
import { onValue, ref } from "firebase/database";

export default {
  name: "IncomingGoods",
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  components: {
    LastUpdate,
  },
  data() {
    return {
      data: undefined,
      sortBy: "artNo",
      sortDesc: false,
      displayStyle: "none",
      filter: "",
      fields: [
        {
          key: "artNo",
          label: "Art. Nr.",
          sortable: true,
        },
        {
          key: "SKU",
          label: "SKU",
          sortable: true,
        },
        {
          key: "discription",
          label: "Wareneingang",
          sortable: true,
        },
        {
          key: "incoming",
          label: "WE",
          sortable: true,
          variant: "success",
        },
        {
          key: "stock",
          label: "Lager",
          sortable: true,
        },
        {
          key: "reserved",
          label: "Reserviert",
          sortable: true,
        },
        {
          key: "available",
          label: "Frei",
          sortable: true,
          variant: "danger",
        },
        {
          key: "ordered",
          label: "Bestellt",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    loadData() {
      onValue(ref(db, "/pages/incomingGoods"), (snapshot) => {
        let data = snapshot.val();
        this.data = data;
      });
    },
    handleScroll() {
      if (window.scrollY > 195 && window.innerWidth > 990) {
        this.displayStyle = "block";
      } else {
        this.displayStyle = "none";
      }
    },
  },
  mounted() {
    this.loadData();

    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
#fixed-table-header {
  position: fixed;
  z-index: 1;
  top: 60px;
  width: 100%;
  left: 0;
  background-color: #fff;
  box-sizing: border-box;
  padding-left: 17px;
  padding-right: 17px;
  border-bottom: 2px solid #dee2e6;
}
#fixed-table-header table {
  width: 100%;
  margin-bottom: 0;
}
#fixed-table-header th {
  border-bottom: 0 !important;
}
</style>

<style>
#incoming-goods .table th:nth-child(1),
#incoming-goods .table th:nth-child(2) {
  width: 160px !important;
}
#incoming-goods .table th:nth-child(4),
#incoming-goods .table th:nth-child(5),
#incoming-goods .table th:nth-child(6),
#incoming-goods .table th:nth-child(7),
#incoming-goods .table th:nth-child(8) {
  width: 120px !important;
}
</style>
