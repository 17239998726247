<template v-if="this.user.loggedIn">
  <div id="on-the-road">
    <div id="fixed-table-header" :style="{ display: displayStyle }">
      <table class="table">
        <thead>
          <tr>
            <th>Bestellnr.</th>
            <th>Hersteller Art. Nr.</th>
            <th>CL Art. Nr.</th>
            <th>Bezeichnung</th>
            <th>OTR</th>
            <th>Lieferdatum</th>
            <th>Lagermenge</th>
            <th>Reserviert</th>
            <th>Bestellt</th>
          </tr>
        </thead>
      </table>
    </div>
    <div class="m-3" v-if="data">
      <LastUpdate page="onTheRoad" class="mt-3" />
      <b-card no-body>
        <template #header>
          <div class="d-flex justify-content-center align-items-center">
            <div class="w-25">
              <b-form-input
                v-model="filter"
                placeholder="Suchen"
                type="search"
                debounce="500"
              ></b-form-input>
            </div>
          </div>
        </template>
        <b-table
          striped
          :items="data"
          :fields="fields"
          :filter="filter"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-compare="sortingChanged"
          :responsive="true"
        >
        </b-table>
      </b-card>
    </div>
    <div v-else class="d-flex justify-content-center mb-3">
      <span v-if="data == null">
        <b-spinner label="Loading..."></b-spinner>
      </span>
      <span v-else>
        <b-alert variant="info" show>
        <b-icon icon="info-circle" scale="1" variant="info" id="tooltip-target-1"></b-icon>  
        Es sind noch keine Daten vorhanden, bitte gucke Später nochmal vorbei.
        </b-alert>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LastUpdate from "../smallComponents/lastUpdate";
import moment from "moment";

import { db } from "../plugins/firebase";
import { onValue, ref } from "firebase/database";

export default {
  name: "OnTheRoad",
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  components: {
    LastUpdate,
  },
  data() {
    return {
      data: null,
      sortBy: "deliveryDate",
      sortDesc: false,
      displayStyle: "none",
      filter: "",
      fields: [
        {
          key: "orderNo",
          label: "Bestellnr.",
          sortable: true,
        },
        {
          key: "artNo",
          label: "Hersteller Art. Nr.",
          sortable: true,
        },
        {
          key: "clArtNo",
          label: "CL Art. Nr.",
          sortable: true,
        },
        {
          key: "description",
          label: "Bezeichnung",
          sortable: true,
        },
        {
          key: "onTheRoad",
          label: "OTR",
          sortable: true,
        },
        {
          key: "deliveryDate",
          label: "Lieferdatum",
          sortable: true,
          sortByFormatted: false,
          filterByFormatted: true,
          formatter: (value) => moment.unix(value).format("DD.MM.YYYY"),
        },
        {
          key: "inStock",
          label: "Lagermenge",
          sortable: true,
        },
        {
          key: "reserved",
          label: "Reserviert",
          sortable: true,
        },
        {
          key: "ordered",
          label: "Bestellt",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    sortingChanged(a, b, key) {
      if (key === 'onTheRoad') {
        const dataA = a[key].replace(".", "");
        const dataB = b[key].replace(".", "");

        return Number(dataA) - Number(dataB);
      }
      return false
    },
    loadData() {
      onValue(
        ref(db, "/pages/onTheRoad"),
        (snapshot) => {
          const data = snapshot.val();
          if(data ){
            this.data = Object.keys(data).map((key) => {
              const dateMoment = moment(data[key].deliveryDate, "DD.MM.YYYY");

              return {
                ...data[key],
                deliveryDate: dateMoment.unix(),
              };
            });
          } else {
            this.data = false
          }       
        }
      );
    },
    handleScroll() {
      if (window.scrollY > 195 && window.innerWidth > 990) {
        this.displayStyle = "block";
      } else {
        this.displayStyle = "none";
      }
    },
  },
  mounted() {
    this.loadData();

    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
#fixed-table-header {
  position: fixed;
  z-index: 1;
  top: 60px;
  width: 100%;
  left: 0;
  background-color: #fff;
  box-sizing: border-box;
  padding-left: 17px;
  padding-right: 17px;
  border-bottom: 2px solid #dee2e6;
}
#fixed-table-header table {
  width: 100%;
  margin-bottom: 0;
}
#fixed-table-header th {
  border-bottom: 0 !important;
}
</style>

<style>
#on-the-road .table th:nth-child(1),
#on-the-road .table th:nth-child(3) {
  width: 160px !important;
}
#on-the-road .table th:nth-child(2) {
  width: 180px !important;
}
#on-the-road .table th:nth-child(5) {
  width: 100px !important;
}
#on-the-road .table th:nth-child(6) {
  width: 140px !important;
}
#on-the-road .table th:nth-child(7),
#on-the-road .table th:nth-child(8),
#on-the-road .table th:nth-child(9) {
  width: 135px !important;
}
</style>
