<template>
  <div>
    <nav
      v-if="this.user.loggedIn === true"
      class="navbar navbar-expand-lg navbar-light bg-primary mb-3"
    >
      <div class="container-fluid">
        <router-link class="navbar-brand d-flex text-white" to="/">
          <Logo
            :fill="'#ffffff'"
            :height="'33px'"
            :width="'33px'"
            class="mb-0 mr-2 d-flex align-items-center"
          ></Logo>
          Apple-Trademaster
        </router-link>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto ml-3">
            <template v-if="this.user.loggedIn === true">
              <b-nav-item>
                <router-link to="/home">Startseite</router-link>
              </b-nav-item>
              <b-nav-item>
                <router-link to="/incoming-goods">Wareneingänge</router-link>
              </b-nav-item>
              <b-nav-item>
                <router-link to="/otr">On the Road</router-link>
              </b-nav-item>
              <b-nav-item>
                <router-link to="/dn-creation">DN Creation</router-link>
              </b-nav-item>
              <b-nav-item>
                <router-link to="/fgr-import">FGR Importieren</router-link>
              </b-nav-item>
              <!-- <b-nav-item> -->
              <a
                class="nav-link"
                :href="`${URL}/?Token=${token}`"
                target="_blank"
                >T4R</a
              >
              <!-- </b-nav-item> -->
              <b-nav-item v-if="this.user.data.admin">
                <router-link to="/settings">Einstellungen</router-link>
              </b-nav-item>
            </template>
          </ul>
        </div>
        <div class="mobile-menu__container">
          <b-dropdown
            id="dropdown-menu"
            text="Menü"
            variant="primary"
            class="m-md-2"
            right
          >
            <b-dropdown-item :to="{ path: '/home' }" variant="primary">
              Startseite
            </b-dropdown-item>
            <b-dropdown-item :to="{ path: '/incoming-goods' }" variant="primary">
              Wareneingänge
            </b-dropdown-item>
            <b-dropdown-item :to="{ path: '/otr' }" variant="primary">
              On the Road
            </b-dropdown-item>
            <b-dropdown-item :to="{ path: '/dn-creation' }" variant="primary">
              DN Creation
            </b-dropdown-item>
            <b-dropdown-item :to="{ path: '/fgr-import' }" variant="primary">
              FGR Importieren
            </b-dropdown-item>
            <b-dropdown-item :href="`${URL}/?Token=${token}`" target="_blank" variant="primary">
              T4R
            </b-dropdown-item>
            <b-dropdown-item v-if="this.user.data.admin" :to="{ path: '/settings' }" variant="primary">
              Einstellungen
            </b-dropdown-item>
          </b-dropdown>

          <div>
            <template v-if="this.user.loggedIn === true">
              <a class="nav-link" @click.prevent="signOut"
                ><font-awesome-icon :icon="['fas', 'sign-out']"
              /></a>
            </template>
          </div>
        </div>
      </div>
    </nav>
    <!-- <CheckUserName/> -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Logo from "../assets/Logo";
import store from "../store";

import { auth, functionsT4R } from "../plugins/firebase";
import { signOut } from "firebase/auth";
import { httpsCallable } from "firebase/functions";

export default {
  name: "NavbarComponent",
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user",
    }),
  },
  data() {
    return {
      token: null,
      URL:
        location.hostname === "localhost"
          ? "http://localhost:8080"
          : "https://trademaster4reseller.comline.app",
      wakeLock: null,
      wakeLockEnabled: false,
      showMenu: false,
    };
  },
  components: {
    Logo,
    // CheckUserName
  },
  methods: {
    async signOut() {
      await store.dispatch("fetchUser", null);
      signOut(auth).then(() => {
        this.user = false;
        this.$router.replace({
          name: "login",
        });
      });
    },
    loginToT4R() {
      if (auth.currentUser) {
        const uid = auth.currentUser?.uid;

        let customToken = httpsCallable(functionsT4R, "generateCustomToken");
        customToken(uid)
          .then((data) => {
            this.token = data.data;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
  beforeMount() {
    this.loginToT4R();
  },
};
</script>
<style>
.navbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
a.nav-link,
li a {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.788) !important;
}
a.nav-link:hover,
li a:hover {
  color: rgb(255, 255, 255) !important;
}
path {
  fill: #fff;
}
.mobile-menu__container{
  display: flex;
  align-items: center;
}
@media screen and (min-width: 990px) {
  #dropdown-menu {
    display: none;
  }
}
</style>
